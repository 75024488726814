
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, Label, Spinner, Input } from "reactstrap";
import PasswordChecklist from "react-password-checklist";
import _ from 'lodash';

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo-dark.png";
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from 'axios';
import Whats from '../../components/Whats/Whats';

class CreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pass_success:false,
            pass_error:false,
            senha:'',
            confirmacaoSenha:'',
            truePassword:false,
        };
    }

        handlePasswordVerify = _.debounce((isValid) => {
            this.setState({ truePassword: isValid });
        }, 300);

        handlePasswordChange = (e) => {
            this.setState({ senha: e.target.value });
        }
    
        handleConfirmPasswordChange = (e) => {
            this.setState({ confirmacaoSenha: e.target.value });
        }
    
        handleSubmit = () => {
            document.getElementById('load').classList.add('active');
            const url = window.location.href;
            const searchParams = new URL(url).searchParams;
    
            const data = {
                token: searchParams.get("token"),
                email: searchParams.get("email"),
                user_type: searchParams.get("t"),
                password: this.state.senha
            };
    
            Axios.post(process.env.REACT_APP_API + '/api/reset-password', data,
            {headers:{"Authorization": "Bearer " + localStorage.getItem('token')}})
            .then(response => {
                this.setState({ pass_success: true });
                document.getElementById('load').classList.remove('active');
                setTimeout(() => {
                    window.open("/login", '_parent');
                }, 300);
            })
            .catch(error => {
                this.setState({ pass_error: true });
                document.getElementById('load').classList.remove('active');
            });
        }

    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/dashboard" className="logo"><img src={logodark} height="20" alt="logo" /></Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Cadastre uma nova senha</h4>
                                                        <p className="text-muted">Para criar uma nova senha, preencha os campos abaixo.</p>
                                                    </div>

                                                    <Container>
                                                        <Row style={{gap:'10px 0', paddingBottom:'15px'}}>
                                                            <Col xs={12}>
                                                                <Label>Crie uma senha</Label>
                                                                <Input type="password" value={this.state.senha} onChange={this.handlePasswordChange}></Input>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <Label>Confirme sua senha</Label>
                                                                <Input type="password" value={this.state.confirmacaoSenha} onChange={this.handleConfirmPasswordChange}></Input>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <PasswordChecklist
                                                                    rules={["minLength","specialChar","number","capital","match"]}
                                                                    minLength={8}
                                                                    iconSize={16}
                                                                    value={this.state.senha}
                                                                    valueAgain={this.state.confirmacaoSenha}
                                                                    onChange={this.handlePasswordChange}
                                                                    messages={{
                                                                        minLength: "Ao menos 8 caracteres.",
                                                                        specialChar: "Ao menos um caractere especial.",
                                                                        number: "Ao menos um número.",
                                                                        capital: "Ao menos uma letra maiúscula.",
                                                                        match: "Suas senhas coincidem.",
                                                                    }}
                                                                    />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col style={{textAlign:'center'}}>
                                                                {this.state.truePassword ?
                                                                    <Button onClick={this.handleSubmit}>Enviar</Button>
                                                                    :
                                                                    <Button disabled>Enviar</Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Container>

                                                    <div className="mt-5 text-center">
                                                        <p>Ainda não tem conta? <Link to="/registre-se" className="fw-medium text-primary"> Registre-se </Link> </p>
                                                        <p>Fast Sale Imóveis</p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                        <div id="load" className="load">
                            <Spinner className="me-2" color="success" />
                        </div>
                    </Container>
                        {/* Sucesso no agendamento */}

                        {this.state.pass_success ? (
                            <SweetAlert
                            title="Senha alterada com sucesso!"
                            timeout={4000}
                            showCloseButton={false}
                            showConfirm={false}
                            success
                            onConfirm={() => this.setState({ pass_success: false })}
                            ></SweetAlert>
                            ) : null}

                        {/* Falha no agendamento */}

                        {this.state.pass_error ? (
                            <SweetAlert
                            title="A alteração falhou, tente novamente."
                            timeout={4000}
                            showCloseButton={false}
                            showConfirm={false}
                            error
                            onConfirm={() => this.setState({ pass_error: false })}
                            ></SweetAlert>
                            ) : null}
                </div>
                <Whats/>
            </React.Fragment>
        );
    }
}

export default CreatePassword;
